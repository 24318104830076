import Vue from 'vue'
import Vuex from 'vuex'
import themeJson from "@/assets/css/theme/theme.json";
import {
	forEach
} from 'lodash';
Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		shopUuid: sessionStorage.getItem('shopUuid') ? sessionStorage.getItem('shopUuid') : '',
		proActive: null,
		userInfo: '',
		// 微信code
		code: null,
		uuid: '',
		openid: localStorage.getItem('openid') ? localStorage.getItem('openid') : '',
		// //测试接口域名地址
		// baseUrl:'http://49.234.183.8:16061',
		// baseUrl: 'http://127.0.0.1:9090',
		// 修改域名地址hwl 正式接口域名地址
		baseUrl: "https://ht.xxssbg.com",
		// 头部log路径
		headUrl: '',
		// 店铺LOGO正方形
		deLogoSquare: localStorage.getItem('deLogoSquare') || '',
		shopInfo: '', // 商店信息
		bgColor: '', //背景 颜色
		productInfo: '', //商品详情 
		productStatus: 2, //1 是详情  2 除了详情
		wxData: {
			appId: '',
			timestamp: '',
			nonceStr: '',
			signature: ''
		},
		showApp: true, //是否显示app
		proType: false, // 产品列表是否需要请求数据

	},
	mutations: {
		setWxData(state, data) {
			state.wxData.appId = data.appId;
			state.wxData.timestamp = data.timestamp;
			state.wxData.nonceStr = data.nonceStr;
			state.wxData.signature = data.signature;
		},
		SET_OPENID(state, openid) {
			localStorage.setItem('openid', openid)
			state.openid = openid

		},
		SET_UUID(state, data) {
			state.uuid = data
		},
		setProType(state, proType) {
			state.proType = proType;
		},
		setProduct(state, p) {
			state.productStatus = p;
		},
		setShowApp(state, p) {
			state.showApp = p;
		},
		SET_PRODUCT_INFO: (state, info) => {
			state.productInfo = info
		},
		SET_BG_COLOR: (state, color) => {
			state.bgColor = color
		},
		SET_SHOP_UUID: (state, shopUuid) => {
			state.shopUuid = shopUuid
			sessionStorage.setItem('shopUuid', shopUuid)
		},
		SET_PRO_ACTIVE: (state, active) => {
			state.proActive = active
		},
		SET_USER_INFO: (state, userInfo) => {
			localStorage.setItem('userInfo', JSON.stringify(userInfo))
			state.userInfo = userInfo
		},
		SET_USER_CODE: (state, code) => {
			state.code = code
		},
		SET_HEADE_URL: (state, headUrl) => {
			state.headUrl = headUrl
		},
		SET_SHOP_INFO: (state, shopInfo) => {
			
			state.shopInfo = shopInfo
		},
		SET_LOGO_SQUARE: (state, deLogoSquare) => {
			state.deLogoSquare = deLogoSquare
		},
		/**
		 * 设置主题色
		 */
		SET_ACTIVE_THEME(state, data) {
			state.activeTheme = data;
			sessionStorage.setItem("activeTheme", data);
			for (let key in themeJson) {
				let theme = themeJson[key];
				if (state.activeTheme === key) {
					for (let var1 in theme) {
						document.getElementsByTagName("body")[0].style.setProperty(var1, theme[var1]);
					}
				}
			}
		},
	},
	actions: {

	},
	modules: {}
})