import axios from 'axios'
import store from '../store'

// 修改域名地址hwl 正式接口域名地址
let baseURL = store.state.baseUrl
const service = axios.create({
	baseURL: baseURL, // url = base url + request url
	timeout: 100000 ,// request timeout
	headers:{
		'Content-Type':'application/x-www-form-urlencoded'
	}
})
service.interceptors.request.use(
	config => {
		if (store.state.userInfo && store.state.userInfo.uuid) {
			config.headers['appUser'] = store.state.userInfo.uuid
		}
		if (store.state.shopUuid) {
			config.headers['appShop'] = store.state.shopUuid
		}
		// if(store.state.userInfo && store.state.userInfo.openid){
		// 	config.headers['wx-open-id'] = store.state.userInfo.openid
		// }
		return config
	},
	error => {
		return Promise.reject(error)
	}
)
service.interceptors.response.use(
	response => {
		return response.data
	},
	error => {
		return Promise.reject(error)
	}
)
export function post(url, params = {}) {
	return new Promise((resolve, reject) => {
		service({
				url: url,
				method: "post",
				data: params,
			})
			.then((response) => {
				resolve(response)
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function get(url, params = {}) {
	return new Promise((resolve, reject) => {
		service({
				url: url,
				method: "get",
				params: params,
			})
			.then((response) => {
				resolve(response)
			})
			.catch((error) => {
				reject(error);
			});
	});
}
