export default{
	nav:{
		home:'首页',
		introduction:'关于',
		category:'分类',
		contact:'联系',
		profile:'我的'
	},
	topNavBar:{
		title:'标题',
		back:'返回'
	},
	home:{
		search:'请输入想要搜索的产品',
		random:'随机视频',
		topOne:'镇店之宝',
		everyday:'燃放日常',
		fireworks:'焰火燃放',
		graphic:'精彩图文',
		recommend:'推荐产品'
	},
	profile:{
		manager:'管理员',
		consumer:'普通用户',
		lookPrice:'查看价格',
		exit:'退出账号',
		personal:'个人资料',
		customerTotal:'申请授权客户',
		scanTotal:'今日扫码量',
		company:'公司名称',
		companyAddress:'公司地址',
		phoneNumber:'手  机  号',
		name:'姓      名',
		submit:'提交申请',
		cart:'我的购物车',
		receipt:'我的进货单',
		collection:'我的收藏',
		record:'浏览记录',
		order:'订单管理',
		dataCenter:'数据中心',
		customer:'客户管理',
		message:'消息中心'
	},
	contact:{
		callme:'联系我们',
		call:'拨打电话',
		toMap:'打开导航',
		address:'地\u3000址 :',
		tel:'电\u3000话 :',
		contacts:'联系人 :',
		email:'邮\u3000箱 :'
	},
	introduction:{
		profile:'企业介绍',
		promotional:'企业介绍'
	},
	category:{
		category:'产品分类'
	}
}