import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)


const title = ''

const routes = [{
		path: '/',
		redirect: '/home',
		meta: {
			title: title,
			index: 0
		}
	},
	/**
	 *首页-----------------------------------------------
	 */
	{
		path: '/home',
		meta: {
			isNavigation: true,
			noKeep: true,
			title: title,
			index: 0
		},
		name: 'Home',
		component: () => import('@v/home/Home.vue')
	},
	/**
	 *产品分类-----------------------------------------------
	 */
	{
		path: '/category',
		meta: {
			isNavigation: true,
			noKeep: true,
			title: '产品分类',
			index: 1
		},
		name: 'Category',
		component: () => import('@v/category/Category.vue')
	},
	/**
	 *企业介绍-----------------------------------------------
	 */
	{
		path: '/introduction',
		meta: {
			isNavigation: true,
			noKeep: true,
			title: '企业介绍',
			index: 2
		},
		name: 'Introduction',
		component: () => import('@v/introduction/Introduction.vue')
	},
	/**
	 *联系我们-----------------------------------------------
	 */
	{
		path: '/contact',
		meta: {
			isNavigation: true,
			title: '联系我们',
			noKeep: true,
			index: 3
		},
		name: 'Contact',
		component: () => import('@v/contact/Contact.vue')
	},
	// 产品列表
	{
		path: '/proList',
		meta: {
			isNavigation: false,
			title: title,
			index: 4
		},
		name: 'ProList',
		component: () => import('@v/proList/ProList.vue')
	},

	/**
	 *视频页中间件
	 -----------------------------------------------
	 */
	{
		path: '/videoMiddle',
		meta: {
			isNavigation: false,
			noKeep: true,
			title: '',
			index: 5
		},
		name: 'VideoMiddle',
		component: () => import('@v/videoPlay/VideoMiddle.vue')
	},
	/**
	 *产品详情-----------------------------------------------
	 */
	{
		path: '/productDetails',
		meta: {
			title: title,
			isNavigation: false,
			noKeep: false,
			keepAlive: false,
			index: 6
		},
		name: 'ProductDetails',
		component: () => import('@v/productDetails/ProductDetails.vue')
	},
	/**
	 *在线视频播放-----------------------------------------------
	 */
	{
		path: '/videoPlay',
		meta: {
			title: '',
			isNavigation: false,
			// noKeep: true,
			keepAlive: false,
			index: 7
		},
		name: 'VideoPlay',
		component: () => import('@v/videoPlay/VideoPlay.vue')
	},
	/**
	 *在线视频播放-----------------------------------------------
	 */
	{
		path: '/randomVideoPlay',
		meta: {
			isNavigation: false,
			// noKeep: true,
			keepAlive: false,
			index: 15,
			title: '随机视频'
		},
		name: 'RandomVideoPlay',
		component: () => import('@v/randomVideoPlay/randomVideoPlay.vue')
	},
	/**
	 *图片页-----------------------------------------------
	 */
	{
		path: '/proPicture',
		meta: {
			isNavigation: false,
			title: title,
			index: 8
		},
		name: 'ProPicture',
		component: () => import('@v/productDetails/components/ProPicture.vue')
	},
	/**
	 *产品总列表-----------------------------------------------
	 */
	{
		path: '/proAllList',
		meta: {
			isNavigation: false,
			noKeep: true,
			title: title,
			index: 9
		},
		name: 'ProAllList',
		component: () => import('@v/proAllList/ProAllList.vue')
	},

	/**
	 *总产品列表-----------------------------------------------
	 */
	{
		path: '/allProList',
		meta: {
			title: '',
			isNavigation: false,
			title: title,
			index: 10
		},
		name: 'AllProList',
		component: () => import('@v/allProList/AllProList.vue')
	},
	/**
	 *我的列表-----------------------------------------------
	 */
	{
		path: '/profile',
		meta: {
			isNavigation: true,
			title: title,
			index: 11
		},
		name: 'Profile',
		component: () => import('@v/profile/Profile.vue')
	},
	/**
	 *分享空间-----------------------------------------------
	 */
	{
		path: '/sharingSpace',
		meta: {
			isNavigation: false,
			title: title,
			index: 12
		},
		name: 'SharingSpace',
		component: () => import('@v/home/views/SharingSpace.vue')
	},
	/*
	 *我的购物车
	 */
	{
		path: '/cart',
		meta: {
			isNavigation: false,
			title: '我的购物车',
			index: 13
		},
		name: 'Cart',
		component: () => import('@v/cart/cart.vue')
	},
	/*
	 *我的进货单
	 */
	{
		path: '/order',
		meta: {
			isNavigation: false,
			title: '我的进货单',
			index: 14
		},
		name: 'Order',
		component: () => import('@v/order/order.vue')
	},
	/*
	 *我的进货单
	 */
	{
		path: '/collection',
		meta: {
			isNavigation: false,
			noKeep: true,
			title: '我的进货单',
			index: 15
		},
		name: 'Collection',
		component: () => import('@v/collection/collection.vue')
	},
	{
		path: '/customer',
		meta: {
			isNavigation: false,
			noKeep: false,
			title: '客户管理',
			index: 16
		},
		name: 'Customer',
		component: () => import('@v/customer/customer.vue')
	},
	{
		path: '/message',
		meta: {
			isNavigation: false,
			noKeep: false,
			title: '消息中心',
			index: 17
		},
		name: 'Message',
		component: () => import('@v/profile/Message.vue')
	},
	{
		path: '/test',
		meta: {
			isNavigation: false,
			noKeep: false,
			title: '消息中心',
			index: 17
		},
		name: 'Test',
		component: () => import('@v/test/test.vue')
	}
	
]

const router = new VueRouter({
	mode: 'history',
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			if (from.meta.keepAlive) {
				from.meta.savedPosition = document.documentElement.scrollTop || document.body.scrollTop;
			}
			return {
				x: 0,
				y: 0
			}
		}
	}
})

export default router
