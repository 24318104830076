import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import './router/beforeEach'
import WaterMark from '@/components/common/WaterMark.vue'
import vueMiniPlayer from 'vue-mini-player'
import 'vue-mini-player/lib/vue-mini-player.css'
import VueClipboard from 'vue-clipboard2'


import {
	wchatConfig
} from '@/api/wechat-config';

const getUrlkey = (name) => {
	return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location
		.href) || [, ''])[1].replace(/\+/g, '%20')) || null;
}
if (getUrlkey('shopUuid')) {
	const shopUuid = getUrlkey('shopUuid')
	store.commit('SET_SHOP_UUID', shopUuid)
}
if (getUrlkey('code')) {
	const code = getUrlkey('code')
	store.commit('SET_USER_CODE', code)
}
if (getUrlkey('uuid')) {
	const uuid = getUrlkey('uuid')
	store.commit('SET_UUID', uuid)
}
if (store.state.shopUuid && !store.state.openid) {
	wchatConfig();
}



store.commit('SET_HEADE_URL', '');
// store.commit('SET_SHOP_UUID', '')
import VueCanvasPoster from 'vue-canvas-poster'
// 注册组件库
Vue.use(VueCanvasPoster)

// 引入下拉刷新
import VueScroller from 'vue-scroller'
Vue.use(VueScroller)

Vue.use(vueMiniPlayer)
Vue.component('WaterMark', WaterMark) // 全局注册一下水印组件

// vant--------------------------------------------
import Vant from 'vant'
import 'vant/lib/index.less'
Vue.use(Vant)
Vue.use(VueClipboard)
Vue.prototype.$store = store
// import VConsole from 'vconsole'
// new VConsole()

// 样式初始化--------------------------------------------
import '@/assets/css/normalize.css'

// amfe-flexible插件-------------------------------------------
import 'amfe-flexible/index.js';
import 'amfe-flexible'
Vue.prototype.bus = new Vue()
Vue.config.productionTip = false;

function setRem() {
	let htmlWidth = document.documentElement.clientWidth || document.body.clientWidth;
	let htmlDom = document.getElementsByTagName('html')[0];
	htmlDom.style.fontSize = (htmlWidth / 10) > 75 ? "75px" : htmlWidth / 10 + 'px'; // 设置1rem最大不超过75px
}
setRem(); // 初始化
window.onresize = function() {
	setRem();
}

/**
 * 缓存的主题色
 */
store.commit('SET_ACTIVE_THEME', sessionStorage.getItem('activeTheme') ? sessionStorage.getItem('activeTheme') : 'blue')

document.addEventListener("WeixinJSBridgeReady", function() {
	WeixinJSBridge.invoke("setFontSizeCallback", {
		fontSize: '2'
	});
}, false);

// i18n
import VueI18n from 'vue-i18n'
import zh from './i18n/zh'
import en from './i18n/en'
Vue.use(VueI18n)
const i18n = new VueI18n({
	// 从本地存储中取，如果没有默认为中文，
	// 这样可以解决切换语言后，没记住选择的语言，刷新页面后还是默认的语言
	locale: localStorage.getItem('lang') || 'zh',
	messages: {
		'zh': zh, // 中文语言包
		'en': en // 英文语言包
	}
})

/** 
 * threejs相关
 *动画
 */
import tween from '@/utils/three/tween'
Vue.use(tween)


export const app = new Vue({
	router,
	store,
	i18n,
	render: h => h(App)
}).$mount('#app')
