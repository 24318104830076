import {
	getConfig
} from './index'
import store from '../store/index'
import {
	Toast
} from 'vant'


function GetRequest() {
	var url = location.href; //获取url中"?"符后的字串 
	var theRequest = new Object();
	if (url.indexOf("?") != -1) {
		var str = url.substr(1);
		strs = str.split("&");
		for (var i = 0; i < strs.length; i++) {
			theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
		}
	}
	return theRequest;
}

function hasPic(arr) {
	let picUrl = ''
	for (var i = 0; i < arr.length; i++) {
		if (arr[i].typeName == "videoPicture" || arr[i].typeName == "prtPicture") {
			picUrl = arr[i].filePath;
			return picUrl;
		}
	}
	return picUrl;
}

function setShare(type,shareData) {
	wx.config({
		debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
		appId: store.state.wxData.appId, // 必填，公众号的唯一标识
		timestamp: store.state.wxData.timestamp, // 必填，生成签名的时间戳
		nonceStr: store.state.wxData.nonceStr, // 必填，生成签名的随机串
		signature: store.state.wxData.signature, // 必填，签名
		jsApiList: ['checkJsApi', 'openLocation', 'onMenuShareTimeline',
			"onMenuShareAppMessage", "scanQRCode",
			'updateTimelineShareData', 'updateAppMessageShareData'
		] // 必填，需要使用的JS接口列表
	});
	wx.checkJsApi({
		jsApiList: ['updateAppMessageShareData',
			'updateTimelineShareData',
			"scanQRCode",
		], // 需要检测的JS接口列表，所有JS接口列表见附录2,
		success: function(res) {
			// 以键值对的形式返回，可用的api值true，不可用为false
			// 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
		}
	})
	wx.ready(function(res) {
		let shop = store.state.shopInfo ? store.state.shopInfo.list : '';
		var name = ''
		var desc = ''
		var img = ''
		var linkUrl = location.href;
		if (type == 1 ) {
			name = shareData.name;
			desc = '我发现了一款产品，' + name + '效果非常棒，大家一起来看看吧';
			img = shareData.url;
		} else {
			name = shop.shopSimpleName ? shop.shopSimpleName : shop.shopName;
			desc = shop.businessIntro ? shop.businessIntro : null;
			img = store.state?.deLogoSquare;
		}
	
		wx.onMenuShareTimeline({
			title: name,
			desc: desc,
			link: linkUrl,
			imgUrl: img,
		});
		wx.onMenuShareAppMessage({
			title: name,
			desc: desc,
			link: linkUrl,
			imgUrl: img,
		});
		wx.updateAppMessageShareData({
			title: name, // 分享标题
			desc: desc, // 分享描述
			link: linkUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
			imgUrl: img, // 分享图标
			success: function() {
				// 设置成功
				console.log('设置分享成功');
			}
		})
		wx.updateTimelineShareData({
			title: name, // 分享标题
			desc: desc,
			link: linkUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
			imgUrl: img, // 分享图标
			success: function() {
				// 设置成功
				console.log('设置分享朋友圈成功');
			}
		})

	})
}

export const wxConfig = (type,shareData) => { //type 1 是详情和播放
	let url = location.href.split('#')[0];
	getConfig({
		url:url
	}).then(res => {
		if(res.state == 100){
			let data = {
				appId: res.items.appId,
				nonceStr: res.items.noncestr,
				signature: res.items.signature,
				timestamp: res.items.timestamp
			}
			store.commit('setWxData', data);
			setShare(type,shareData)
		}
	})
}