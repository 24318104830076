/**
 * 微信js-sdk
 */
export function WeixinJs(){
	return new Promise(function(resolve, reject) {
		window.init = function() {
			resolve(qq) //注意这里
		}
		var script = document.createElement("script");
		script.type = "text/javascript";
		script.src = "https://res2.wx.qq.com/open/js/jweixin-1.6.0.js";
		script.onerror = reject;
		document.head.appendChild(script);
	})
	
}