import request from '@/utils/request'

// 区县--------------------------------------------
export function getArea(params) {
  return request({
    url: '/web/v1/base/baseCityApi/getArea',
    method: 'get',
    params
  })
}

// 城市--------------------------------------------
export function getCity(params) {
  return request({
    url: '/web/v1/base/baseCityApi/getCity',
    method: 'get',
    params
  })
}

// 省份--------------------------------------------
export function getProvince(params) {
  return request({
    url: '/web/v1/base/baseCityApi/getProvince',
    method: 'get',
    params
  })
}

// 街道--------------------------------------------
export function getStreet(params) {
  return request({
    url: '/web/v1/base/baseCityApi/getStreet',
    method: 'get',
    params
  })
}

// 根据code获取地址
export const codeToAddr = async (provinceCode, cityCode, areaCode, streetCode) => {
  const { items: provinces = [] } = await getProvince()
  const provinceName = (provinces || []).filter(o => o.code == provinceCode)?.[0]?.name

  const { items: citys = [] } = await getCity({ code: provinceCode })
  const cityName = (citys || []).filter(o => o.code == cityCode)?.[0]?.name

  const { items: areas = [] } = await getArea({ code: cityCode })
  const areaName = (areas || []).filter(o => o.code == areaCode)?.[0]?.name

  const { items: streets = [] } = await getStreet({ code: areaCode })
  const streetName = (streets || []).filter(o => o.code == streetCode)?.[0]?.name
  return provinceName + cityName + areaName + streetName
}
