import store from "@/store/index.js"

// 半年以内的算新true,半年以上的算热false

export const isNew = (date) => {
	const stamp1 = new Date(date).getTime()
	const stamp2 = new Date().getTime()
	const s = Math.floor(Math.abs(stamp1 - stamp2) / 1000)
	const y = Math.floor(s / 3600 / 24 / 365)
	const m = Math.floor(s / 3600 / 24 % 365 / 30)
	if (y > 1) {
		return false
	} else if (y < 1 && m > 6) {
		return false
	} else {
		return true
	}
}

export const setPageTitle = (title) => {
	if (title) {
		document.title = title;
	}
	var i = document.createElement("iframe");
	i.src = "http://m.baidu.com/favicon.ico";
	i.style.display = "none";
	document.body.appendChild(i);
	i.onload = function () {
		setTimeout(function () {
			i.remove();
		}, 1);
	};
}
//设置渐变背景色
export const setBgColor = (key1, key2) => {
	let bgColor = store.state.bgColor;
	let carouselColor1 = '#0957f6';
	let carouselColor2 = '#008aff';
	for (let i = 0; i < bgColor.length; i++) {
		if (bgColor[i].item_key == key1) {
			carouselColor1 = bgColor[i].item_value
		}
		if (bgColor[i].item_key == key2) {
			carouselColor2 = bgColor[i].item_value
		}
	}
	return 'linear-gradient(90deg,' + carouselColor1 + ',' + carouselColor2 + ')'
}

// 时间戳转时间
export function timestampToTime (timestamp) {
	var date = new Date(parseInt(timestamp));//时间戳为10位需*1000，时间戳为13位的话不需乘1000
	var Y = date.getFullYear() + '.';
	var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '.';
	var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
	var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
	var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
	var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
	return Y + M + D;
}
