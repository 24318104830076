<template>
  <div class="water-mark">
    <!-- <img src="~@/assets/img/home/jishu.png" alt="" /> -->
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.water-mark {
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > img {
    width: 151px;
    height: 15px;
  }
}
</style>