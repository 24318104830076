<template>
  <van-tabbar v-model="active" :class="shadowPosition" @change="onChange">
    <van-tabbar-item v-for="(tab, index) of tabList" :key="index" replace>
      <span
        class="tab-title"
        :style="{ color: active === index ? tab.activeColor : '' }"
        >{{ tab.title }}</span
      >
      <template #icon="props">
        <div class="tab-item" :class="props.active ? 'isActive' : ''">
          <div class="img-box">
            <div class="img-bg">
              <!-- 原版写法存在问题, 这种写法, 每点击一次都会请求一次图片 -->
              <img :src="props.active ? tab.icon.active : tab.icon.inactive" />
            </div>
          </div>
        </div>
      </template>
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
import home_active from "@/assets/img-v3/new-home/1.png";
import home from "@/assets/img-v3/new-home/11.png";
import category_active from "@/assets/img-v3/new-home/2.png";
import category from "@/assets/img-v3/new-home/22.png";
import introduction_active from "@/assets/img-v3/new-home/3.png";
import introduction from "@/assets/img-v3/new-home/33.png";
import contact_active from "@/assets/img-v3/new-home/4.png";
import contact from "@/assets/img-v3/new-home/44.png";
import profile_active from "@/assets/img-v3/new-home/5.png";
import profile from "@/assets/img-v3/new-home/55.png";

export default {
  name: "Navigation",
  data() {
    return {
      active: 0,
      tabList: [
        {
          to: "/introduction",
          title: this.$t('nav.introduction'),
          activeColor: "#0897FF",
          icon: {
            active: introduction_active,
            inactive: introduction,
          },
        },
        {
          to: "/category",
          title: this.$t('nav.category'),
          activeColor: "#FF078A",
          icon: {
            active: category_active,
            inactive: category,
          },
        },
        {
          to: "/home",
          title: this.$t('nav.home'),
          activeColor: "#FF4A07",
          icon: {
            active: home_active,
            inactive: home,
          },
        },
        {
          to: "/contact",
          title: this.$t('nav.contact'),
          activeColor: "#00CDFD",
          icon: {
            active: contact_active,
            inactive: contact,
          },
        },
        {
          to: "/profile",
          title: this.$t('nav.profile'),
          activeColor: "#9B0EFF",
          icon: {
            active: profile_active,
            inactive: profile,
          },
        },
      ],
      fullPath: "",
      closeWindoUrls: [
        "/home" + "?shopUuid=" + this.shopUuid,
        "/category" + "?shopUuid=" + this.shopUuid,
        "/introduction" + "?shopUuid=" + this.shopUuid,
        "/contact" + "?shopUuid=" + this.shopUuid,
        "/videoPlay" + "?shopUuid=" + this.shopUuid,
        "/profile" + "?shopUuid=" + this.shopUuid,
      ],
    };
  },
  computed: {
    shadowPosition() {
      let className;
      switch (this.active) {
        case 0:
          className = "shadow-position0";
          break;
        case 1:
          className = "shadow-position1";
          break;
        case 2:
          className = "shadow-position2";
          break;
        case 3:
          className = "shadow-position3";
          break;
        case 4:
          className = "shadow-position4";
          break;
      }
      return className;
    },
    shopUuid() {
      return this.$store.state.shopUuid;
    },
  },
  beforeMount() {
    // 根据不同策略提前锁定当前选中
    for (let i = 0; i < this.tabList.length; i++) {
      if (this.$route.path === this.tabList[i].to) {
        this.active = i;
        break;
      }
    }
  },
  mounted() {
    const { fullPath } = this.$route;
    this.fullPath = fullPath;
  },
  methods: {
    onChange(index) {
      this.active = index;
      this.$router.push({
        path: this.tabList[index].to,
        query: { shopUuid: this.shopUuid },
      });
    },
    // 监听手机左滑右滑
   
  },
};
</script>

<style lang="less" scoped>
// .nav-shadow{
//   height: 82.5px;
//   background:url('~@/assets/img/nav-shadow.png') no-repeat;
//   // background-size: 375px 60px;
//   background-size: 1125px auto;
//   background-position: -500px -120px;
// }
.van-tabbar-item--active {
  background-color: transparent;
}

[class*="van-hairline"]::after {
  border: none;
}

.van-tabbar {
  background: url("~@/assets/img-v3/new-home/nav-shadow.png") no-repeat;
  // background-size: auto 100px;
  background-size: auto 65px;
  z-index: 9999;
  height: 60px;
  border: none;
  outline: none;
  padding-top: 5.5px;

  /deep/ .van-tabbar-item {
    // background-color: #ffffff;
    .van-tabbar-item__icon {
      height: 22px;
      width: 100%;

      .tab-item {
        // position: relative;
        svg {
          position: absolute;
          top: 3px;
          left: 0;
          z-index: -10;
        }
        .img-box {
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 22px;
            height: 22px;
            position: relative;
            z-index: 2;
          }
        }
      }
      .tab-item.isActive {
        // width: 105px;
        margin-top: -33px;
        // background: url("~@/assets/img/shadow.png") no-repeat;
        // background: url("~@/assets/img/s1.png") no-repeat;
        background-size: 100% 100%;
        .img-box {
          .img-bg {
            background-color: transparent;
            border-radius: 50%;
            width: 56px;
            height: 56px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            &::after {
              content: "";
              width: 56px;
              height: 56px;
              display: block;
              position: absolute;
              top: -30px;
              left: -30px;
            }

            &::before {
              content: "";
              width: 56px;
              height: 56px;
              display: block;
              position: absolute;
            }

            img {
              width: 54px;
              height: 54px;
            }
          }
        }

        &::after {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }

    .tab-title {
      margin-top: 6px;
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #969696;
      /*color: #646464;
      &.active {
        color: #FF4A07;
      }*/
    }
  }
}

.shadow-position0 {
  background-position: calc(-507.5px + 10vw) 0;
}
.shadow-position1 {
  background-position: calc(-507.5px + 30vw) 0;
}
.shadow-position2 {
  background-position: calc(-507.5px + 50vw) 0;
}
.shadow-position3 {
  background-position: calc(-507.5px + 70vw) 0;
}
.shadow-position4 {
  background-position: calc(-507.5px + 90vw) 0;
}

/deep/ .van-tabbar-item {
  // height: 60px;
  position: relative;
  .shadow-img {
    position: absolute;
    width: 94px;
    height: 95px;
    // height: 80px;
    left: 0;
    top: -45px;
    z-index: -999;
  }
}
</style>
