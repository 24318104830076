<template>
	<div id="app">

		<template v-if="showBlock">
			<div class="empty">
				您已被拉黑
				<div class="empty">请联系管理员</div>
			</div>
		</template>
		<template v-else>
			<template v-if="showApp">
				<keep-alive>
					<router-view v-if="$route.meta.noKeep" />
				</keep-alive>
				<router-view v-if="!$route.meta.noKeep" />

				<Navigation v-if="$route.meta.isNavigation" />
			</template>
			<div v-else class="empty">
				该店铺暂时无法访问
				<div class="empty">请联系管理员</div>
			</div>
		</template>


	</div>
</template>

<script>
	import Navigation from "@c/navigation/Navigation.vue";
	import {
		getFileList,
		getShopOne,
		getAllBgColor
	} from "@/api";
	import {
		codeToAddr
	} from "@/api/addr";
	import {
		wxConfig
	} from "@/api/wxApi";
	import {
		WeixinJs
	} from '@/utils/tools.js'
	import {
		setPageTitle
	} from '@/utils/index'
	
	import {
		wchatConfig
	} from '@/api/wechat-config';
	
	WeixinJs()
	export default {
		name: "App",
		components: {
			Navigation,
		},
		data() {
			return {
				transitionName: "",
			};
		},
		computed: {
			showApp() {
				return this.$store.state.showApp;
			},
			showBlock() {
				if (this.$store.state.userInfo && this.$store.state.userInfo.block == 1) {
					return true
				} else {
					return false
				}
			}
		},
		watch: {
			$route(to, from) {
				//如果to索引大于from索引,判断为前进状态,反之则为后退状态
				if (to.meta.index > from.meta.index) {
					//设置动画名称
					this.transitionName = "slide-left";
				} else {
					this.transitionName = "slide-right";
				}
				const {
					shopUuid
				} = to.query;
				if (shopUuid) {
					this.$store.commit("SET_SHOP_UUID", shopUuid);
					this.getFileList(shopUuid);
					this.getShopOne(shopUuid, false);
					this.getAllBgColor(shopUuid);
				}
				if(to.name != 'ProductDetails' && to.name != 'VideoPlay'){
					wxConfig();
				}
			},
		},

		beforeMount() {
			this.$store.commit("SET_HEADE_URL", "");
			let shopUuid = this.getUrlKey('shopUuid')
			if (shopUuid) {
				this.$store.commit("SET_SHOP_UUID", shopUuid);
				let url = location.href.split('#')[0];
				if(url.indexOf('videoPlay') == -1 && url.indexOf('productDetails') == -1){
					wxConfig();
				}
			}
			if(this.getUrlKey('code')){
				wchatConfig()
			}
		},
		created() {
			this.$store.commit('setShowApp', true)
			const {
				shopUuid
			} = this.$store.state;
			if (shopUuid) {
				this.$store.replaceState(
					Object.assign(
						this.$store.state,
						JSON.parse(localStorage.getItem("beforeunload"))
					)
				);
				window.addEventListener("beforeunload", () => {
					const state = JSON.stringify(this.$store.state);
					localStorage.setItem("beforeunload", state);
				});

			}
		},
		methods: {
			
			getAllBgColor(shopUuid) {
				getAllBgColor({
					uuid: shopUuid || this.$store.state.shopUuid
				}).then(
					(res) => {
						if (res.state == 100) {
							this.$store.commit("SET_BG_COLOR", res.items);
						}
					}
				);
			},
			// 获取列表数据
			getFileList(shopUuid) {
				const params = {
					page: 0,
					pageSize: 0,
					typeName: "deLogoRectangle,deLogoSquare",
					parentUuid: shopUuid || this.$store.state.shopUuid,
				};
				getFileList(params).then((res) => {
					if (res.state == 100) {
						const {
							items
						} = res;
						const headUrl = items.filter((o) => o.typeName == "deLogoRectangle");
						this.$store.commit("SET_HEADE_URL", headUrl[0]?.filePath ?? "");
						const deLogoSquare = items.filter(
							(o) => o.typeName == "deLogoSquare"
						);
						localStorage.setItem("deLogoSquare", deLogoSquare[0]?.filePath ?? "");
						this.$store.commit(
							"SET_LOGO_SQUARE",
							deLogoSquare[0]?.filePath ?? ""
						);
					}
				});
			},

			// 获取用户详情
			async getShopOne(shopUuid, showTitle) {

				await getShopOne({
					uuid: shopUuid
				}).then((res) => {
					const {
						items
					} = res;
					if (res.state == 102) { //判断 店铺是否关闭
						this.$store.commit('setShowApp', false)
						return
					} else {
						this.$store.commit('setShowApp', true)
						this.$store.commit("SET_SHOP_INFO", items);
						let shopName = items.list?.shopName;
						if (shopName) {
							setPageTitle(shopName)
						}
						// 通过code转化地址
						const shopAddr = items.list?.shopAddr || "";
						const detailAddr = items.list?.detailAddr;
						const shopAddrCode = shopAddr.split(",");
						codeToAddr(
							shopAddrCode[0],
							shopAddrCode[1],
							shopAddrCode[2],
							shopAddrCode[3]
						).then((res) => {
							const totalShopAddr = res + detailAddr;
							localStorage.setItem("totalShopAddr", totalShopAddr);
						});

					}

				});

			},
			//获取url里面的值
			getUrlKey(name) {
				return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [
					'',
					''
				])[1].replace(/\+/g, '%20')) || null;
			}
		},
	};
</script>

<style>
	@import "assets/css/common.css";
	@import "assets/css/theme/theme.scss";

	#app {
		max-width: 750px;
		/* 插件会自动解析成10rem */
		margin: 0 auto;
		font-size: 14px;
		width: 100%;
		font-family: HarmonyOS Sans SC;
		height: 100%;
		color: #323232;
	}

	span {
		color: #787878;
	}

	.slide-right-enter-active,
	.slide-right-leave-active,
	.slide-left-enter-active,
	.slide-left-leave-active {
		will-change: transform;
		transition: all 200ms;
		position: absolute;
	}

	.text-cut {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}

	.slide-right-enter {
		opacity: 0;
		transform: translate3d(-100%, 0, 0);
	}

	.slide-right-leave-active {
		opacity: 0;
		transform: translate3d(100%, 0, 0);
	}

	.slide-left-enter {
		opacity: 0;
		transform: translate3d(100%, 0, 0);
	}

	.slide-left-leave-active {
		opacity: 0;
		transform: translate3d(-100%, 0, 0);
	}

	.empty {
		text-align: center;
		padding-top: 100px;
	}
</style>