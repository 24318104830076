export default{
	nav:{
		home:'Home',
		introduction:'Introduction',
		category:'Category',
		contact:'Contact',
		profile:'Profile'
	},
	topNavBar:{
		title:'Title',
		back:'Back'
	},
	home:{
		search:'Please Search Product',
		random:'Random',
		topOne:'TopOne',
		everyday:'Everyday',
		fireworks:'Fireworks',
		graphic:'Graphic',
		recommend:'Recommend'
	},
	profile:{
		manager:'Manager',
		consumer:'Consumer',
		lookPrice:'LookPrice',
		exit:'Exit',
		personal:'Personal',
		customerTotal:'CustomerTotal',
		scanTotal:'ScanTotal',
		company:'Company',
		companyAddress:'CompanyAddress',
		phoneNumber:'PhoneNumber',
		name:'Name',
		submit:'Submit',
		cart:'Cart',
		receipt:'Receipt',
		collection:'Collection',
		record:'Record',
		order:'Order',
		dataCenter:'DataCenter',
		customer:'Customer',
		message:'Message'
	},
	contact:{
		callme:'Call US',
		call:'Call Tell',
		toMap:'Go Map',
		address:'Address :',
		tel:'Tel :',
		contacts:'Contact :',
		email:'Email :'
	},
	introduction:{
		profile:'CompanyProfile',
		promotional:'Promotional'
	},
	category:{
		category:'Category'
	}
}