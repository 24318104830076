
import {
	getConfig,
	scanCode,
	findUserInfo
} from './index'

import store from '@/store/index.js'
import {
	Toast
} from 'vant'
const getQueryString = (name) => {
	//企业微信授权获取code问题解决办法
	const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
	const result = window.location.search.substring(1).match(reg)
	if (result != null) {
		return decodeURIComponent(result[2])
	}
	return null
}

export const wchatConfig = (data) => {
	const url = location.href.split('#')[0];
	const openid = store.state.openid
	return new Promise((resolve, reject) => {
		// 判断是否授权
		if (!openid) {
			let code = getQueryString('code')
			if (code) {
				// 获取openid
				scanCode({
					code
				}).then(res => {
					if (res.state == 100) {
						// 清除 code 等参数
						// let valiable = window.location.href.split("?")[0];
						// window.location.href = valiable
						
						localStorage.setItem('userInfo', JSON.stringify(res.items))
						store.commit('SET_USER_INFO', res.items)
						localStorage.setItem('appUser', res.items.uuid)
						window.wx_uuid = res.items.uuid;
						const state = JSON.stringify(store.state)
						localStorage.setItem('beforeunload', state)
						store.commit('SET_OPENID', res.items.openid)
						findUserInfo({
							wxOpenId: store.state.userInfo.openid,
							shopUuid: store.state.shopUuid
						}).then(resp =>{
							if (resp.state == 100 && resp.items) {
								localStorage.setItem('userInfo', JSON.stringify(resp.items))
								store.commit('SET_USER_INFO', resp.items)
								localStorage.setItem('appUser', resp.items.uuid)
								window.wx_uuid = resp.items.uuid;
							}
						})
					}
				})
			}
		}
	})
}

function hasPic(arr) {
	let picUrl = ''
	for (var i = 0; i < arr.length; i++) {
		if (arr[i].typeName == "videoPicture" || arr[i].typeName == "prtPicture") {
			picUrl = arr[i].filePath;
			return picUrl;
		}
	}
	return picUrl;
}