import request from '@/utils/request'
import {
	post,get
} from '@/utils/axios'

// 修改获取单条用户详情
export function getShopOne(params) {
	return request({
		url: '/web/v1/shop/Shop/appData/getShopOne',
		method: 'get',
		params
	})
}
// 条件查询文件
export function getFileList(params) {
	params.wxUuid = window.wx_uuid;
	return request({
		url: '/web/v1/upload/centerOptionFile/appData/getFileList',
		method: 'get',
		params
	})
}

// 获取产品类型列表
export function getProTypeList(params) {
	return request({
		url: '/web/v1/prt/productType/appData/getList',
		method: 'get',
		params
	})
}

// app端获取产品类型列表
export function getAppProTypeList(params) {
	return request({
		url: '/web/v1/prt/productType/appData/getAppList',
		method: 'get',
		params
	})
}

// 获取产品列表
export function getProList(params) {
	return request({
		url: '/web/v1/prt/product/appData/getList',
		method: 'get',
		params
	})
}
// 获取产品列表
export function getProList2(params) {
	return request({
		url: '/web/v1/prt/product/appData/getList2',
		method: 'get',
		params
	})
}
// 获取单个产品
export function getProOne(params) {
	return request({
		url: '/web/v1/prt/product/appData/getOne',
		method: 'post',
		params
	})
}

// 获取微信二维码
export function getQrCode(params) {
	return request({
		url: '/app/WeChat/getQrCode',
		method: 'get',
		params
	})
}

export function wxLog(params) {
	return request({
		url: '/app/WeChat/log/wx',
		method: 'get',
		params
	})
}

// 获取微信config配置参数
export function getConfig(params) {
	return request({
		url: '/app/WeChat/getConfig',
		method: 'get',
		params
	})
}

export function scanCode(params) {
	return request({
		url: '/app/WeChat/scanCode',
		method: 'get',
		params
	})
}
export function scanCode2(params) {
	return request({
		url: '/app/WeChat/scanCode2',
		method: 'post',
		params
	})
}



// 获取/视频/图文/日常《公众号 调用的接口》
export function getSharingSpace(params) {
	return request({
		url: '/web/v1/ShopPicVideo/appData/getList',
		method: 'get',
		params
	})
}

// 公众号点击查看详情调用此接口增加点击量
export function clickAddOne(params) {
	return request({
		url: '/web/v1/ShopPicVideo/appData/clickAddOne',
		method: 'POST',
		params
	})
}

// 喜欢列表
export function getCollList(params) {
	return request({
		url: '/web/v1/prt/product/appData/getCollList',
		method: 'get',
		params
	})
}

// 足迹
export function getFootprint(params) {
	return request({
		url: '/web/v1/prt/product/appData/getFootprint',
		method: 'get',
		params
	})
}
// 公众号/取消产品收藏/喜欢
export function reCollection(params) {
	return request({
		url: '/web/v1/prt/product/appData/reCollection',
		method: 'POST',
		params
	})
}
// 公众号/产品收藏/喜欢
export function collections(data) {
	return request({
		url: '/web/v1/prt/product/appData/collections',
		method: 'POST',
		data
	})
}

// 公众号/取消产品收藏/喜欢
export function reCollections(data) {
	return request({
		url: '/web/v1/prt/product/appData/reCollections',
		method: 'POST',
		data
	})
}



// 收藏喜欢
export function collection(data) {
	return request({
		url: '/web/v1/prt/product/appData/collection',
		method: 'POST',
		data
	})
}

//  公众号《《产品点击量》》查看详情调用此接口增加点击量
export function clickPrtAddOne(params) {
	return request({
		url: '/web/v1/prt/product/appData/clickPrtAddOne',
		method: 'POST',
		params
	})
}

export function shopAddOne(params) {
	return request({
		url: '/web/v1/shop/Shop/appData/addOne',
		method: 'get',
		params
	})
}

// 新品上市
export function getNewProduct(params) {
	return request({
		url: '/web/v1/prt/product/appData/getNewProduct',
		method: 'get',
		params
	})
}

// 猜你喜欢
export function getYouLike(params) {
	return request({
		url: '/web/v1/prt/product/appData/getYouLike',
		method: 'get',
		params
	})
}
//浏览记录
export function browsingHistory(params) {
	return request({
		url: '/web/v1/prt/product/appData/browsingHistory',
		method: 'get',
		params
	})
}


//修改购物车数据
export function addToCart(params) {
	return request({
		url: '/web/v1/shop/order/appData/addToCart',
		method: 'post',
		params
	})
}
// 获取购物车
export function getCart(params) {
	return request({
		url: '/web/v1/shop/order/appData/getCart',
		method: 'post',
		params
	})
}
// 删除购物车
export function removeCart(data) {
	return request({
		url: '/web/v1/shop/order/appData/removeCart',
		method: 'post',
		data
	})
}

//获取背景色
export function getAllBgColor(params) {
	return request({
		url: '/web/v1/shop/ShopConfig/appData/getAll',
		method: 'get',
		params
	})
}

// 随机视频
export function randomVideo(params) {
	return request({
		url: '/web/v1/upload/centerOptionFile/appData/random/video',
		method: 'get',
		params
	})
}

// 生产订货单
export function generateOrder(data) {
	return request({
		url: '/web/v1/shop/order/appData/generateOrder',
		method: 'POST',
		data
	})
}

// 获取我的订单
export function getOrder(params) {
	return request({
		url: '/web/v1/shop/order/appData/getOrder',
		method: 'get',
		params
	})
}
//获取二维码
export function productQRCode() {
	return "/app/v1/appData/productQRCode?uuid="
}

export function productQRCode2() {
	return "/app/v1/appData/pQRCode?uuid="
}

//导出订单
export function exportOrder() {
	return "/web/v1/shop/order/appData/export?orderId="
};


/**
 * 申请列表
 * @param {*} params 
 */

export function listWarrant(params) {
	return request({
		url: '/app/WeChat/listWarrant',
		method: 'POST',
		params
	})
}
//客户管理列表
export function listCustomer(params) {
	return request({
		url: '/app/WeChat/listCustomer',
		method: 'POST',
		params
	})
}



//申请客户

export function warrant(params) {
	return request({
		url: '/app/WeChat/warrant',
		method: 'POST',
		params
	})
}

//今日扫码量

export function getTodayScanCount(params) {
	return request({
		url: '/app/WeChat/getTodayScanCount',
		method: 'get',
		params
	})
}
//修改tag 等级
export function updateUser(params) {
	return request({
		url: '/app/WeChat/updateUser',
		method: 'POST',
		params
	})
}
/**
 * 查询申请信息
 */
export function findWarrant(params) {
	return request({
		url: '/app/WeChat/findWarrant',
		method: 'POST',
		params
	})
}
/**
 * 查询用户信息
 */
export function findUserInfo(params) {
	return request({
		url: '/app/WeChat/findUserInfo',
		method: 'POST',
		params
	})
}

 